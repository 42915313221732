import React from 'react'

import Layout from '../../components/layout';
import AsyncDownload from '../../components/async-download';
/*
import RoundedIconButton from "../components/rounded-icon-button";
import FaRight from '../img/font-awesome/regular/arrow-right.svg';
*/
import {graphql} from "gatsby";

import DownloadMacSafari from "../../img/SVG/download-safari-mac.svg";
import DownloadMacChrome from "../../img/SVG/download-chrome-mac.svg";
import DownloadMacFirefox from "../../img/SVG/download-firefox-mac.svg";

import InstallGif from "../../img/organizer/install-mac.gif";
import NonStretchedImage from '../../components/non-stretchd-image';

const {detect} = require('detect-browser');


function justFileName(url)
{
    return url.match(/([^/]*)$/)[0];
}


class DownloadPageMac extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            fileName: justFileName(this.props.data.site.siteMetadata.downloadMac),
            browser: "safari"
        };
    }


    componentDidMount()
    {
        const browser = detect();

        this.setState({
            browser: browser.name
        });
    }


    render()
    {
        let text;

        if (this.state.browser === "chrome")
        {
            text = <>
                <p>
                    Bitte warte, bis das Herunterladen abgeschlossen ist, dann klicke
                    auf die Schaltfäche mit der Aufschrift <b>{this.state.fileName}</b> unten links in der Ecke
                    dieses Fensters.
                </p>
                <DownloadMacChrome style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
            </>;
        }
        else if (this.state.browser === "firefox")
        {
            text = <>
                <p>
                    FireFox fragt Dich, was Du mit der Datei <b>{this.state.fileName}</b> machen willst.
                    Wähle <b>Öffnen mit: DiskImageMounter (default)</b> und klicke auf <b>Ok</b>.
                    FireFox sollte dann automatisch das <a href="#installing">Installations-Fenster</a> anzeigen.
                </p>
                <p>
                    Falls das nicht klappt, klicke auf den kleinen Pfeil oben rechts in der Ecke:
                </p>
                <DownloadMacFirefox style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                    Warte, bis das Herunterladen abgeschlossen ist und klicke dann auf <b>{this.state.fileName}</b>
                    um mit der Installation zu beginnen.
                </p>
            </>;
        }
        else /* Safari */
        {
            text = <>
                <p>
                    Das Herunterladen sollte in Safari automatisch starten.
                    Klicke auf den Pfeil oben rechts in diesem Fenster und warte, bis
                    das Herunterladen abgeschlossen ist.
                </p>
                <DownloadMacSafari style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                    Klicke auf das <b>{this.state.fileName}</b> und fahre mit der Installation fort.
                </p>
            </>;
        }

        return (
            <Layout lang={'de'}
                    className={'nav--no_border'}
                    title={'2stitch Organizer - Stickdateien auf Macs anzeigen'}
                    description={'Gratis für macOS. Stick-Dateien anschauen, sortieren und finden. Unterstützt Bernina, Brother (PES), Janome (SEW), Pfaff (PCS), Husquarna (HUS, VIP)'}
                    translated={{en: '/download-organizer-mac/'}}
                    location={this.props.location.pathname}
            >

                <div className={'bg-blue-50 text-blue-900 pb-12 pt-12 border-t border-b border-blue-900'}>
                    <div className="container mx-auto">
                        <AsyncDownload
                            url={this.props.data.site.siteMetadata.downloadMac}
                            productName={'2stitch Organizer'}
                        />
                    </div>
                </div>

                <div className={'container mx-auto mt-12 markdown'}>
                    <h2>2stitch Organizer auf deinem Mac herunterladen</h2>

                    {text}

                    <h2 id="installing">2stitch Organizer auf deinem Mac installieren</h2>
                    <p>
                        Wenn die Schritte aus dem vorherigen Abschitt geklappt haben
                        solltest Du das folgende Fenster auf dem Bildschirm sehen.

                        Ist dies nicht der Fall versuche den Ordner mit dem Namen <b>Downloads</b> in
                        Finder zu finden und mache dann einen Doppel-Klick auf die Datei <b>{this.state.fileName}</b>.
                    </p>

                    <p>
                        Ziehe das Icon <b>2stitch Organizer.app</b> mit der Maus nach
                        rechts in den <b>Applications</b>-Ordner:
                    </p>

                    <img className={'mx-auto'} src={InstallGif}
                         alt={"Installation von 2stitch Organizer auf einem Mac."}/>

                    <p>
                        2stitch Organizer ist nun installiert. Öffne Finder wähle den
                        Ordner <b>Programme</b> aus. Doppel-Klicke das <b>2stitch Organizer</b> Icon
                        um das Programm zu starten.
                    </p>

                    <p>macOS zeigt Dir nun eine Warnung, dass diese App
                        aus dem Internet von www.codeandweb.com runtergeladen wurde.
                        (Das ist ok: 2stitch ist ein Produkt der CodeAndWeb GmbH.)
                        Klicke auf <b>Öffnen</b>.
                    </p>

                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.installMacWarningImage.childImageSharp.fluid}/>
                    </div>


                    {/*
                    <div className="text-center mt-8">
                        <RoundedIconButton to={"/organizer-getting-started"} icon={FaRight}>
                            Getting started
                        </RoundedIconButton>
                    </div>
                    */}
                </div>

            </Layout>
        )
            ;
    }
}

export default DownloadPageMac;

export const query = graphql`
  query DownloadPageMacQueryDe {
    site {
      siteMetadata {
        downloadMac
      }
    }

    installMacWarningImage: file(relativePath: { eq: "organizer/install-mac-warning-de.png" }) {
        childImageSharp {
            fluid(quality: 100, maxWidth:593) {
              ...GatsbyImageSharpFluid
              presentationWidth
        }
        }
    },

    firstStart: file(relativePath: { eq: "organizer/first-start-mac.png" }) {
        childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
        }
        }
    },
  }
`;
